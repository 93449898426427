* {
  box-sizing: border-box;
}

body {
  min-width: 320px;
  margin: 0;
  padding: 0;
  font-family: Lato, sans-serif;
}

body .split {
  display: flex;
}

body .split > * {
  flex-grow: 1;
  width: 50%;
}

body .split > .vCenter {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

body > header.header {
  color: #fff;
  background-color: #0a2c73;
}

body > header.header h1 {
  margin: 0 0 12px;
  font-size: 56px;
  font-weight: 800;
  line-height: 64px;
}

body > header.header .subheader {
  font-size: 24px;
  line-height: 32px;
}

body > header.header .middle {
  justify-content: space-between;
  max-width: 1480px;
  margin: 0 auto;
  padding: 172px 128px 80px;
  display: flex;
}

body > header.header .top {
  z-index: 2;
  background-color: #0a2c73;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 72px;
  padding: 20px 24px;
  display: flex;
  position: fixed;
  top: 0;
}

body > header.header .top .logo.mobile {
  z-index: 9;
  position: relative;
}

body > header.header .top.white {
  color: #05174b;
  background-color: #fff;
}

body > header.header .top.white #mainMenu.desktop a.menuItem, body > header.header .top.white #mainMenu.desktop .menuTitle {
  color: #05174b;
}

body > header.header .top.white #mainMenu.desktop .transparent.menuButton {
  color: #05174b;
  border-color: #05174b;
}

body > header.header .top.white .logoText {
  fill: #05174b;
}

body > .content {
  max-width: 1480px;
  margin: 0 auto;
}

body > .content .block {
  color: #05174b;
  margin: 0 128px 160px;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}

body > .content .block h1 {
  font-size: 56px;
  font-weight: 800;
  line-height: 64px;
}

body > .content .block h2 {
  margin: 0 0 12px;
  font-size: 32px;
  font-weight: 800;
  line-height: 40px;
}

body > .content .block h2 > img {
  margin-right: 18px;
}

body > .content .block p {
  margin: 0 0 16px;
}

body > .content .block.redLine {
  border-left: 4px solid #e52c34;
  margin: 120px 128px;
  padding-left: 40px;
}

body > .content .block:last-child {
  margin-top: 80px;
  margin-bottom: 80px;
}

body > .content .center {
  text-align: center;
}

body > .content h2.h1Like, body > .wide-content h2.h1Like {
  font-size: 56px;
  font-weight: 800;
  line-height: 64px;
}

body form .input-message {
  color: #e52c7a;
  font-size: 14px;
  line-height: 24px;
  display: none;
}

body form a {
  color: #4c76f3;
}

body form button {
  text-align: center;
  color: #fff;
  cursor: pointer;
  background: #e52c34;
  border: 0;
  border-radius: 2px;
  justify-content: center;
  margin-top: 55px;
  padding: 8px 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  display: flex;
}

body form button.success {
  padding-right: 45px;
  position: relative;
}

body form button.success:after {
  content: " ";
  background-color: #fff;
  width: 8px;
  height: 2px;
  display: inline-block;
  position: absolute;
  top: calc(50% + 4px);
  right: 30px;
  transform: rotate(45deg);
}

body form button.success:before {
  content: " ";
  background-color: #fff;
  width: 15px;
  height: 2px;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 21px;
  transform: rotate(-60deg);
}

body form button.invalid {
  opacity: .3;
}

body form.invalid > .input-message, body form.half > .input-message {
  display: block;
}

@media only screen and (width <= 1280px) {
  body > header.header .middle {
    padding: 170px 60px 80px;
  }

  body > .content .block, body > .content .block.redLine {
    margin: 80px 60px;
  }
}

@media only screen and (width <= 775px) {
  body > header.header .middle {
    padding: 72px 16px 32px;
  }

  body > header.header .middle .left h1, body > header.header .middle .left h2.h1Like {
    font-size: 40px;
    line-height: 54px;
  }

  body .content .block {
    margin: 120px 16px;
  }

  body .content .block h1, body .content .block h2.h1Like {
    margin-bottom: 12px;
    font-size: 40px;
    line-height: 54px;
  }

  body .content .block h2 {
    font-size: 24px;
    line-height: 32px;
  }

  body .content .block .product {
    margin: 40px -16px;
  }

  body .content .block.redLine {
    margin-left: 0;
  }

  body .content .block.redLine p:last-child {
    margin-bottom: 0;
  }

  body .content .content .block {
    margin: 120px 16px;
  }

  body .content .content .block .product {
    margin: 40px -16px;
  }

  body .content .content .block.redLine {
    margin-left: 0;
  }

  body .content .content .center {
    text-align: inherit;
  }
}
/*# sourceMappingURL=index.3b970004.css.map */
