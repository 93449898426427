@import './colors.scss';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  min-width: 320px;
  font-family: 'Lato', sans-serif;

  .split {
    display: flex;

    > * {
      width: 50%;
      flex-grow: 1;

      &.vCenter {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  >header.header {
    background-color: $ui_06;
    color: $ui_01;

    h1 {
      font-size: 56px;
      line-height: 64px;
      font-weight: 800;
      margin: 0 0 12px 0;
    }

    .subheader {
      font-size: 24px;
      line-height: 32px;
    }

    .middle {
      display: flex;
      padding: 172px 128px 80px;
      max-width: 1480px;
      margin: 0 auto;
      justify-content: space-between;
    }

    .top {
      padding: 20px 24px;
      position: fixed;
      top: 0;
      background-color: $ui_06;
      display: flex;
      height: 72px;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      z-index: 2;

      .logo.mobile {
        position: relative;
        z-index: 9;
      }

      &.white {
        background-color: $ui_01;
        color: $text_01;
        
        #mainMenu.desktop {
          a.menuItem,
          .menuTitle {
            color: $text_01;
          }

          .transparent.menuButton {
            color: $text_01;
            border-color: $text_01;
          }
        }

        .logoText {
          fill: $neutral_01;
        }
      }
    }
  }

  >.content {
    max-width: 1480px;
    margin: 0 auto;

    .block {
      margin: 0 128px 160px;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      color: $text_01;

      h1 {
        font-weight: 800;
        font-size: 56px;
        line-height: 64px;
      }

      h2 {
        font-weight: 800;
        font-size: 32px;
        line-height: 40px;
        margin: 0 0 12px 0;

        >img {
          margin-right: 18px;
        }
      }

      p {
        margin: 0 0 16px 0;
      }

      &.redLine {
        border-left: 4px solid $accent_01;
        padding-left: 40px;
        margin: 120px 128px;
      }

      &:last-child {
        margin-bottom: 80px;
        margin-top: 80px;
      }
    }

    .center {
      text-align: center;
    }
  }
  
  >.content, >.wide-content {
    h2.h1Like {
      font-size: 56px;
      font-weight: 800;
      line-height: 64px;
    }
  }

  form {
    .input-message {
      display: none;
      font-size: 14px;
      line-height: 24px;
      color: $error_01;
    }

    a {
      color: $linkLight;
    }

    button {
      padding: 8px 20px;
      background: $accent_01;
      border-radius: 2px;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      text-align: center;
      color: $ui_01;
      border: 0;
      margin-top: 55px;
      cursor: pointer;
      display: flex;
      justify-content: center;

      &.success {
        position: relative;
        padding-right: 45px;

        &::after {
          content: " ";
          width: 8px;
          height: 2px;
          background-color: $ui_01;
          display: inline-block;
          position: absolute;
          right: 30px;
          transform: rotate(45deg);
          top: calc(50% + 4px);
        }
        
        &::before {
          content: " ";
          width: 15px;
          height: 2px;
          background-color: $ui_01;
          display: inline-block;
          position: absolute;
          right: 21px;
          transform: rotate(-60deg);
          top: 50%;
        }
      }

      &.invalid {
        opacity: 0.3;
      }
    }

    &.invalid, &.half {
      > .input-message {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  body {
    > header.header .middle {
      padding: 170px 60px 80px;
    }

    >.content .block, >.content .block.redLine {
      margin: 80px 60px;
    }
  }
}

@media only screen and (max-width: 775px) {
  body > header.header .middle {
    padding: 72px 16px 32px;

    .left h1, .left h2.h1Like {
      font-size: 40px;
      line-height: 54px;
    }
  }

  body .content {
    .block {
      margin: 120px 16px;

      h1, h2.h1Like {
        font-size: 40px;
        line-height: 54px;
        margin-bottom: 12px;
      }

      h2 {
        font-size: 24px;
        line-height: 32px;
      }

      .product {
        margin: 40px -16px;
      }

      &.redLine {
        margin-left: 0;

        p:last-child {
          margin-bottom: 0;
        }
      }
    }

    .content {
      .block {
        margin: 120px 16px;
  
        .product {
          margin: 40px -16px;
        }
  
        &.redLine {
          margin-left: 0;
        }
      }
  
      .center {
        text-align: inherit;
      }
    }
  }
}
